document.querySelectorAll('.top-menu li a').forEach(function (link) {
    link.addEventListener('click', function () {
        var subMenu = this.nextElementSibling;

        if (!subMenu)
            return;

        if (subMenu.classList.contains('active')) {
            subMenu.classList.remove('active');
        } else {
            subMenu.classList.add('active');
        }
    });
});


/* #region mobile main menu */
document.getElementById('trigger-mobile-menu').addEventListener('click', (e: Event) => {
    e.stopPropagation();
    document.getElementById('slide-out').style.right = '0';
});

document.addEventListener('click', (event: Event) => {
    var slideOut = <HTMLElement>document.getElementById('slide-out');

    var target = event.target as HTMLElement;
    if (!target.closest('#slide-out') && target !== slideOut) {
        slideOut.style.right = '-250px';
    }
});
